// libraries
import React, { useState } from 'react';

import logoBehaviour from 'images/teams/logoBehaviour.png';
import logoBlackBirdInteractive from 'images/teams/logoBlackBirdInteractive.png';
import logoBlizzard from 'images/teams/logoBlizzard.png';
import logoCreativeAssembly from 'images/teams/logoCreativeAssembly.png';
import logoDeepSilverVolition from 'images/teams/logoDeepSilverVolition.png';
import logoEA from 'images/teams/logoEA.png';
import logoEidosMontreal from 'images/teams/logoEidosMontreal.png';
import logoEncoreHollywood from 'images/teams/logoEncoreHollywood.png';
import logoESLUK from 'images/teams/logoESLUK.png';
import logoMTIFilm from 'images/teams/logoMTIFilm.png';
import logoQuanticDream from 'images/teams/logoQuanticDream.png';
import logoRainbowStudios from 'images/teams/logoRainbowStudios.png';
import logoRemedy from 'images/teams/logoRemedy.png';
import logoSquareEnix from 'images/teams/logoSquareEnix.png';
import logoToeiZukunLab from 'images/teams/logoToeiZukunLab.png';
import logoUbisoft from 'images/teams/logoUbisoft.png';

import { styled } from 'style2';

interface Props {
  className?: string;
}

export default function LogosCarousel(props: Props) {
  const { className } = props;

  const [page, setPage] = useState(0);

  return (
    <Wrapper className={className}>
      <Title>Trusted by creatives & teams everywhere</Title>

      <Slides>
        <Slide css={{ transform: `translateX(${page * -100}%)` }}>
          <Logo src={logoEA} alt="EA" />
          <Logo src={logoBlizzard} alt="Blizzard" />
          <Logo src={logoUbisoft} alt="Ubisoft" />
          <Logo src={logoSquareEnix} alt="Square Enix" />
          <Logo src={logoRemedy} alt="Remedy" />
        </Slide>
        <Slide css={{ transform: `translateX(${page * -100}%)` }}>
          <Logo src={logoMTIFilm} alt="MTI Film" />
          <Logo src={logoRainbowStudios} alt="Rainbow Studios" />
          <Logo src={logoEncoreHollywood} alt="Encore Hollywood" />
          <Logo src={logoToeiZukunLab} alt="Toei Zukun Lab" />
          <Logo src={logoCreativeAssembly} alt="Creative Assembly" />
        </Slide>
        <Slide css={{ transform: `translateX(${page * -100}%)` }}>
          <Logo src={logoBehaviour} alt="Behaviour" />
          <Logo src={logoBlackBirdInteractive} alt="Blackbird Interactive" />
          <Logo src={logoDeepSilverVolition} alt="Deep Silver Volition" />
          <Logo src={logoEidosMontreal} alt="Eidos Montreal" />
          <Logo src={logoESLUK} alt="ESL UK" />
          <Logo src={logoQuanticDream} alt="Quantic Dream" />
        </Slide>
      </Slides>

      <Dots>
        <li>
          <Dot active={page === 0} onClick={() => setPage(0)} />
        </li>
        <li>
          <Dot active={page === 1} onClick={() => setPage(1)} />
        </li>
        <li>
          <Dot active={page === 2} onClick={() => setPage(2)} />
        </li>
      </Dots>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  padding: '8rem 0 6rem',
  backgroundColor: '#ffffff'
});

const Title = styled('h3', {
  textAlign: 'center',
  fontWeight: 'bold',
  padding: '0 8.2rem',
  marginBottom: '5rem'
});

const Slides = styled('ul', {
  display: 'flex',
  overflow: 'hidden'
});

const Slide = styled('li', {
  width: '100%',
  flexShrink: 0,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 500ms ease-in-out'
});

const Logo = styled('img', {
  display: 'block',
  maxWidth: '20rem',
  maxHeight: '5rem',
  margin: '0 2.5rem 2rem'
});

const Dots = styled('ul', {
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '1.6rem'
});

const Dot = styled('button', {
  width: '1rem',
  height: '1rem',
  padding: 0,
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: '$handheldMedium',
  border: 'none',
  transition: 'background-color 125ms ease',
  variants: {
    active: {
      true: {
        backgroundColor: '$pitchBlack',
        cursor: 'default'
      }
    }
  }
});
