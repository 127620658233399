// libraries
import React, { ReactNode } from 'react';

import { styled } from 'style2';

interface Props {
  className?: string;
  children: ReactNode;
}

export default function Bubble(props: Props) {
  const { className, children } = props;

  return <Wrapper className={className}>{children}</Wrapper>;
}

const Wrapper = styled('div', {
  overflow: 'hidden',
  borderRadius: '$medium',
  boxShadow: '0 0.2rem 0.6rem rgba(0, 0, 0, 0.3)'
});
